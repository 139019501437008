<template>
  <Modal v-model="choiceDeviceModal" width="1200" footer-hide>
    <p slot="header" class="text-center">添加资产</p>
    <Row :gutter="8" class="m-b-5">
      <i-col span="4">
        <Checkbox v-model="onlyChk">只看已选</Checkbox>
       <!-- <CheckBox>只看已选</CheckBox> -->
      </i-col>
      <i-col span="16" class="m-b-5">
        <i-input
          size="small"
          v-model="query.keyword"
          placeholder="关键字"
        ></i-input>
      </i-col>
      <i-col span="4">
        <Button
          size="small"
          type="primary"
          icon="ios-search"
          @click="SearchData"
          >搜索</Button
        >
      </i-col>
    </Row>
    <Table
      size="small"
      :columns="columnManagedata"
      :data="tableData"
      stripe
    ></Table>
    <div class="paging_style">
      <Page
        size="small"
        :total="total"
        :page-size="query.pageSize"
        show-total
        show-elevator
        :current="query.pageNumber"
        @on-change="changePage"
      ></Page>
    </div>
    <!-- <div slot="footer">
      <Button type="text" class="m-r-5" @click="choiceDeviceModal = false"
        >关闭</Button
      >
      <Button type="primary" @click="handleConfirmChk">确定</Button>
    </div> -->
  </Modal>
</template>

<script>
import { saveSpaceDevice } from '@/api/product/leaseSpace'
import { getEstateDevicePage2 } from '@/api/rim/device'
export default {
  props: {
    deviceArray: {
      type: Array
    }
  },
  data () {
    return {
      choiceDeviceModal: false,
      total: 0,
      query: {
        pageNumber: 1,
        pageSize: 10,
        deviceBigmodel: 3,
        keyword: ''
      },
      choiceDeviceIdArray: [],

      tableData: [],
      onlyChk: false,
      columnManagedata: [
        { title: '编号', key: 'code' },
        { title: '类型', align: 'center', key: 'devicemodelName' },
        { title: '站点', align: 'center', key: 'stationName' },
        { title: '出口', align: 'center', key: 'exits' },
        { title: '楼层', align: 'center', key: 'floor' },
        { title: '位置', align: 'center', key: 'positionName' },
        { title: '面积（㎡）', align: 'center', key: 'area' },
        { title: '状态', align: 'center', key: 'enabledName' },
        { title: '经营方', align: 'center', ellipsis: true, tooltip: true, key: 'manageUnitName' },
        {
          title: '租赁类型',
          align: 'center',
          key: 'openLease',
          render: (h, params) => {
            return h('span', params.row.openLease ? '开放租赁' : '自用')
          }
        },
        {
          title: '通电',
          align: 'center',
          key: 'electric',
          render: (h, params) => {
            return h('span', params.row.electric ? '是' : '否')
          }
        },
        {
          title: '通水',
          align: 'center',
          key: 'waterSupply',
          render: (h, params) => {
            return h('span', params.row.waterSupply ? '是' : '否')
          }
        },
        {
          title: '通气',
          align: 'center',
          key: 'gasSupply',
          render: (h, params) => {
            return h('span', params.row.gasSupply ? '是' : '否')
          }
        },
        {
          title: '消防验收',
          align: 'center',
          key: 'fireApproval',
          render: (h, params) => {
            return h('span', params.row.fireApproval ? '是' : '否')
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            const addButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.chkDevice(params.row.id)
                  }
                }
              },
              '加入'
            )

            return h('div', [this.choiceDeviceIdArray.includes(params.row.id) ? '-' : addButton])
          }
        }
      ]
    }
  },
  mounted () {},
  methods: {
    showModal () {
      // this.publicityInfoModel.investId = this.investmentId
      // listSinInBusiness({ investId: this.investmentId }).then((res) => {
      //   this.businessArray = res.filter((x) => x.orderStatus === 1)
      // })
      this.initPageData()
      this.choiceDeviceModal = true
    },
    hideModal () {
      this.choiceDeviceModal = false
    },
    initPageData () {
      if (this.onlyChk && !this.choiceDeviceIdArray.length) {
        this.tableData = []
        this.total = 0
        this.query.pageNumber = 1
      } else {
        this.query.deviceIdList = this.onlyChk ? JSON.stringify(this.choiceDeviceIdArray) : null
        getEstateDevicePage2(this.query).then((response) => {
          this.total = response.totalRow
          this.tableData = response.list
          this.query.pageNumber = response.pageNumber
        })
      }
    },

    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initPageData()
    },
    SearchData () {
      this.query.pageNumber = 1
      this.initPageData()
    },
    chkDevice (deviceId) {
      saveSpaceDevice({ deviceId: deviceId, spaceId: this.spaceId }).then(res => {
        this.$store.commit('set_space_update', new Date())
      })

      // if (this.choiceDeviceIds.includes(deviceId)) {
      //   this.choiceDeviceIds.splice(this.choiceDeviceIds.indexOf(deviceId), 1)
      //   // this.choiceDeviceIdArray = this.choiceDeviceIdArray.filter(x => x !== deviceId)
      // } else {

      // }
    }
    // handleConfirmChk () {
    //   this.hideModal()
    //   this.$store.commit('set_investment_chkDeviceArray', this.choiceDeviceIds)
    // }

  },
  watch: {
    onlyChk () {
      this.SearchData()
    },
    deviceArray () {
      this.choiceDeviceIdArray = this.deviceArray.map(item => {
        return item.deviceId
      })
      this.initPageData()
    }
  },
  computed: {
    // choiceDeviceIdArray () {
    //   return this.$store.state.rentalSpace.chkDeviceArray
    // },
    spaceId () {
      return this.$store.state.rentalSpace.spaceId
    }
  }
}
</script>
